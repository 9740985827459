<template>
  <core-view :style="{ '--delay': delay }">
    <core-view-header
      :name="'shipment'"
      :count="totalItems"
      :description="'page_descriptions.labels'"
      :routeName="$route.name"
      route="labels"
      showViewName
    >
    </core-view-header>
    <core-list-new-table
      :items="items"
      :headers="headers"
      :viewType="viewType"
      :loading="loading"
      :orderBy.sync="options.orderBy"
      :orderDir.sync="options.orderDir"
      :currentPage.sync="options.page"
      :query.sync="search"
      :selected="selected"
      :excluded="excluded"
      :show-create-new="true"
      @create-new-clicked="showCreateShipment"
      :createNewLabel="$t('send_package')"
      :selectionKey="selectionKey"
      :totalItems="totalItems"
      :rowActions="rowActions"
      :bulkActions="bulkActions"
      @checkbox-change="handleSelection"
      :selectAll.sync="selectAll"
      @set-cleaned-filters="val => $emit('set-cleaned-filters', val)"
      @set-filters="val => $emit('set-filters', val)"
      :entityName="name"
      :emptyListIcon="icon"
      :pageSize="options.itemsPerPage"
      pushPath="labels_details"
    >
      <template v-slot:[`column.receiver.name`]="{ item }">
        <core-list-cell-templates-receiver-with-flag
          :name="item.receiver.name"
          :countryCode="item.receiver.country"
        />
      </template>
      <template v-slot:[`column.package_number`]="{ item }">
        <div style="cursor: copy" @click.stop="$clipboard(item.package_number)">
          {{ item.package_number }}
        </div>
      </template>

      <template v-slot:[`column.labelless_code`]="{ item }">
        <div style="cursor: copy" @click.stop="$clipboard(item.labelless_code)">
          {{ item.labelless_code }}
        </div>
      </template>

      <template v-slot:[`column.carrier`]="{ item }">
        <core-list-cell-templates-carrier-with-image
          :carrier="item.carrier"
          :url="URL_PREFIX"
        />
      </template>

      <template v-slot:[`column.order_price`]="{ item }">
        <p class="small content">
          {{ getPrice(item) }}
        </p>
      </template>
      <template v-slot:[`column.latest_tracking_event.title`]="{ item }">
        <div
          v-if="isInactive(item) || item.latest_tracking_event"
          class=" h-7 max-w-min rounded tracking-button"
          :class="
            borderColor(
              isInactive(item)
                ? 'cancelled'
                : item.latest_tracking_event.event_code
            )
          "
        >
          <p
            class="text-sm px-2 pt-1 truncate"
            :class="
              textColor(
                isInactive(item)
                  ? 'cancelled'
                  : item.latest_tracking_event.event_code
              )
            "
          >
            {{
              isInactive(item)
                ? $t("is_cancelled")
                : $t(item.latest_tracking_event.event_sub_code || "no_tracking")
            }}
          </p>
        </div>
        <p v-else class="small content">{{ $t("no_tracking") }}</p>

        <div
          v-if="!isInactive(item)"
          class="tracking-modal whitespace-normal break-words text-sm bg-gray-50 w-80 shadow-md rounded-md p-4 opacity-0 absolute -left-20 pointer-events-none transition duration-300 ease-in-out"
          style="word-break:break-word;"
        >
          <p
            v-if="item.latest_tracking_event"
            class="w-80 whitespace-normal break-words	text-sm pr-6"
            style="word-break:break-word;"
          >
            {{ item.latest_tracking_event.title }}
          </p>
        </div>
      </template>
    </core-list-new-table>
    <core-tracking-modal :packageNumber.sync="trackPackage" />
    <core-json-modal
      @close="modals.jsonDialog.visible = false"
      :visible="modals.jsonDialog.visible"
      :json="modals.jsonDialog.json"
      :title="$t('orderlines')"
    />
    <return-shipment-dialog
      :visible.sync="modals.returnShipmentDialog.visible"
      :shipment="modals.returnShipmentDialog.shipment"
      @close-returns-dialog="
        () => (modals.returnShipmentDialog.visible = false)
      "
      @refresh-data="() => $emit('refresh-data')"
    />

    <core-create-return-portal
      :visible.sync="modals.returnPortalDialog.visible"
      :shipment="modals.returnPortalDialog.shipment"
    />
    <core-create-claim
      :visible.sync="modals.createClaim.visible"
      @update:visible="val => !val ? [modals.createClaim.shipment = null, getDataFromApi()] : null"
      :shipment="modals.createClaim.shipment"
    />

    <core-dialog-gallery
      @close="modals.galleryDialog.visible = false"
      :visible="modals.galleryDialog.visible"
      :images="modals.galleryDialog.images"
    />
  </core-view>
</template>

<script>
import I18n from "@/plugins/i18n";
import ESList from "@/mixins/ESList";
import { convertUTCToLocal } from "@/utils/date";
import { URL_PREFIX, API_PREFIX } from "@/utils/constants";
import ReturnShipmentDialog from "./components/ReturnShipmentDialog";
import EventBus from "@/plugins/eventbus";

export default {
  mixins: [ESList],
  name: "LabelsList",
  components: { ReturnShipmentDialog },
  data: () => ({
    viewType: "shipments",
    endpoint: "shipments",
    exportType: "label",
    name: "shipment",
    icon: ["fal", "box-alt"],
    trackPackage: null,
    selectionKey: "id",
    modals: {
      returnShipmentDialog: {
        visible: false,
        shipment: null
      },
      returnPortalDialog: {
        visible: false,
        shipment: null
      },
      jsonDialog: {
        visible: false,
        orderlines: null
      },
      galleryDialog: {
        visible: false,
        images: []
      },
      createClaim: {
        visible: false,
        shipment: null
      }
    },
    headers: [
      {
        label: "date",
        key: "created_at",
        type: "date",
        minWidth: "11"
      },
      {
        label: "receiver",
        key: "receiver.name",
        orderableKey: "receiver_name",
        minWidth: "11"
      },
      {
        label: "carrier",
        key: "carrier",
        type: "carrier_image",
        minWidth: "11"
      },
      {
        label: "labelless_code",
        key: "labelless_code",
        minWidth: "10"
      },
      {
        label: "package_number",
        key: "package_number",
        minWidth: "12"
      },
      {
        label: "reference",
        key: "reference",
        minWidth: "11"
      },
      {
        label: "price",
        key: "order_price",
        orderableKey: "price",
        minWidth: "12"
      }
    ]
  }),
  created() {
    if (
      this.$store.state.breakpoint.isSmall ||
      this.$store.state.breakpoint.noMatch
    ) {
      this.headers = [
        {
          label: "date",
          key: "created_at",
          type: "date"
        },
        {
          label: "receiver",
          key: "receiver.name",
          orderableKey: "receiver_name"
        },
        {
          label: "package_number",
          key: "package_number"
        }
      ];
    } else if (this.$store.state.account?.level == "vip") {
      this.headers = [
        {
          label: "date",
          key: "created_at",
          type: "date",
          minWidth: "11"
        },
        {
          label: "receiver",
          key: "receiver.name",
          orderableKey: "receiver_name",
          minWidth: "11"
        },
        {
          label: "carrier",
          key: "carrier",
          type: "carrier_image",
          minWidth: "11"
        },
        {
          label: "latest_tracking_event",
          key: "latest_tracking_event.title",
          minWidth: "10"
        },
        {
          label: "package_number",
          key: "package_number",
          minWidth: "12"
        },
        {
          label: "reference",
          key: "reference",
          minWidth: "11"
        },
        {
          label: "price",
          key: "order_price",
          orderableKey: "price",
          minWidth: "12"
        }
      ];
    }
  },
  watch:{
    itemsLoaded: function(val) {
      if (val && this.$route.query.state == 'intro') {
        this.items = [{
          id: '1234test',
          created_at: '2023-03-20T08:02:33.000000Z',
          receiver: {
            name: 'Lars Test',
            country: 'DK'
          },
          carrier: 'bring',
          latest_tracking_event: {
            title: 'IN_TRANSIT',
            event_code: 'IN_TRANSIT',
            event_sub_code: 'IN_TRANSIT'
          },
          labelless_code: '123-456-789',
          package_number: '0002586845',
          reference: 'Ordre: 123585',
          order_price: {
            excl_tax: '20',
            incl_tax: '25'
          }
        }]
        this.totalItems = 1
      }
    },
  },
  computed: {
    URL_PREFIX: () => URL_PREFIX,
    API_PREFIX: () => API_PREFIX,
    extraRowActions: function() {
      return [
        {
          icon: ["far", "images"],
          action: item => {
            this.modals.galleryDialog.visible = true;
            this.modals.galleryDialog.images = this.generateImageUrls(item);
          },
          hidden: item => !item.image_count
        }
      ];
    },
    rowActions: function(entity) {
      let actions = [
        {
          label: I18n.t("download_label"),
          icon: ["far", "file-download"],
          link: item =>
            `${API_PREFIX}/business-panel/shipments/${item.package_number}/label?access_token=${this.$store.state.auth.access_token}&account_id=${this.$store.state.account_id}`
        },
        {
          label: I18n.t("track_shipment"),
          icon: ["far", "radar"],
          action: item => (this.trackPackage = item.package_number)
        },
        {
          label: I18n.t("create_return_portal_link"),
          icon: ["far", "undo"],
          action: item => {
            this.modals.returnPortalDialog.visible = true;
            this.modals.returnPortalDialog.shipment = item;
          }
        },
        {
          label: I18n.t("resend_return_label", {
            entity: I18n.tc("image", 99).toLowerCase()
          }),
          icon: ["far", "envelope"],
          action: item => {
            this.resendReturnEmail(item)
          },
          hidden: item => !item.is_return
        },
        {
          label: I18n.t("duplicate_shipment"),
          icon: ["far", "clone"],
          action: item => {
            EventBus.$emit("SHOW_CREATE_SHIPMENT", item);
          }
        },
        {
          label: I18n.t("see_entity", {
            entity: I18n.tc("image", 99).toLowerCase()
          }),
          icon: ["far", "images"],
          action: item => {
            this.modals.galleryDialog.visible = true;
            this.modals.galleryDialog.images = this.generateImageUrls(item);
          },
          hidden: item => !item.image_count
        },
        {
          label: I18n.t("cancel_entity", {
            entity: I18n.tc(this.name).toLowerCase()
          }),
          icon: ["far", "radar"],
          action: item => {
            this.cancelShipment(item);
          },
          hidden: item => !item.cancellable
        },
        {
          label: I18n.t("delete_receiver_data", {
            entity: I18n.tc(this.name).toLowerCase()
          }),
          icon: ["far", "user"],
          action: item => {
            this.deleteReceiverData(item);
          }
        },
        {
          label: I18n.t("orderlines"),
          icon: ["far", "brackets-curly"],
          action: item => {
            this.modals.jsonDialog.visible = true;
            this.modals.jsonDialog.json = item.order_lines;
          },
          hidden: item => !(item.order_lines && item.order_lines.length > 0)
        }
      ];

      if (this.$store.state?.printServices?.length) {
        actions.splice(0, 0, {
          label: I18n.t("print_entity", {
            entity: I18n.tc("label", 1).toLowerCase()
          }),
          icon: ["far", "print"],
          action: item => {
            EventBus.$emit("SHOW_PRINT_DIALOG", printer =>
              this.printLabel(printer, item)
            );
          }
        });
      }
      if (this.$store.state?.account?.business) {
        actions.splice(3, 0, {
          label: I18n.t("create_claim"),
          icon: ["fas", "exclamation-triangle"],
          action: item => {
            this.modals.createClaim.visible = true;
            this.modals.createClaim.shipment = item;
          },
          hidden: item => item.deleted_at || item.cancel_at || item.cancelled_at || item.ticket_id
        });
      }
      if (this.$store.state?.account?.business) {
        actions.splice(1, 0, {
          label: `${I18n.t("show")} ${I18n.t("case").toLowerCase()}`,
          icon: ["fas", "exclamation-triangle"],
          action: item => {
            this.$router.push({ name: 'labels_details', params: { id: item.id } });
          },
          hidden: item => !item.ticket_id
        });
      }
      if (this.$store.state?.account?.business)
        actions.splice(2, 0, {
          label: I18n.t("create_entity", {
            entity: I18n.t("return_shipment").toLowerCase()
          }),
          icon: ["far", "undo"],
          action: item => {
            this.modals.returnShipmentDialog.visible = true;
            this.modals.returnShipmentDialog.shipment = item;
          }
        });
      return actions;
    },
    bulkActions: function() {
      return this.exportActions;
    },
    delay: function() {
      return this.items.length * 0.03;
    }
  },
  methods: {
    isInactive: function(item) {
      return item.cancel_at || item.deleted_at || item.active === false;
    },

    printLabel: function(printer, item) {
      this.$get(`${this.endpoint}/${item.package_number}/print/${printer}`)
        .then(() => {
          this.$notification({
            title: this.$t("request send"),
            text: this.$t("print_should_start"),
            type: "success"
          });
        })
        .catch(({ response }) => {
          this.$notification({
            title: this.$t("print_failed"),
            type: "error"
          });
        });
    },
    deleteReceiverData: function(item) {
      this.$confirm(
        this.$t("confirm_delete", {
          type: this.$tc(this.name).toLowerCase()
        })
      ).then(() => {
        const id = Date.now();
        this.$notification({
          id: id,
          title: this.$t("delete_started"),
          duration: -1
        });
        this.$get(`${this.endpoint}/${item.package_number}/gdpr`, {
          failSilently: true
        })
          .then(response => {
            this.$notification.close(id);
            this.$notification({
              title: this.$t("receiver_data_deleted"),
              duration: 2500
            });
          })
          .catch(e => {
            this.$notification.close(id);
            this.$notification({
              title: this.$t("receiver_data_delete_failed"),
              type: "error"
            });
          });
      });
    },
    convertUTCToLocal: function(val, type) {
      return convertUTCToLocal(val, type);
    },
    generateImageUrls: function(entity) {
      let images = [];
      for (let i = 0; i < entity.image_count; i++) {
        images.push(
          `${URL_PREFIX}/business-panel/shipments/${entity.package_number}/image/${i}`
        );
      }
      return images;
    },
    showCreateShipment: function() {
      EventBus.$emit("SHOW_CREATE_SHIPMENT");
    },
    cancelShipment: function(item) {
      this.$confirm(
        this.$t("confirm_cancellation", {
          type: this.$tc(this.name).toLowerCase()
        })
      ).then(() => {
        this.loading = true;
        this.$del(`/${this.endpoint}/${item.id}`)
          .then(() => {
            this.getDataFromApi();
            this.$notification({
              title: this.$t("success"),
              text: `${this.$tc(this.name)} ${this.$t(
                "has_been_cancelled"
              ).toLowerCase()}`,
              type: "success"
            });
          })
          .catch(e => {
            this.$notification({
              title: this.$t("not_cancelled"),
              text: `${this.$tc(this.name)} ${this.$t(
                "has_not_been_cancelled"
              ).toLowerCase()}`,
              type: "error"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    resendReturnEmail: function (shipment) {
      const id = Date.now();
        this.$notification({
          id: id,
          title: this.$t("sending_entity", {
            entity: this.$tc("label", 1).toLowerCase()
          }),
          duration: -1
        });
        this.$get(`returns/resend-return-email/${shipment.id}`, {
          failSilently: true
        })
          .then(response => {
            this.$notification.close(id);
            this.$notification({
              title: this.$t("entity_sent", {
                entity: this.$tc("label", 1).toLowerCase()
              }),
              duration: 2500
            });
          })
          .catch(e => {
            this.$notification.close(id);
            this.$notification({
              title: this.$t("error"),
              type: "error"
            });
          });
    },
    borderColor: function(val) {
      switch (val) {
        case "BOOKED":
          return "border border-gray-300 bg-gray-100";

        case "INFO":
          return "border border-yellow-300";

        case "TRANSIT":
          return "border border-blue-500";

        case "DELIVERED":
          return "border border-green-400";

        case "DEVIATION":
        case "cancelled":
          return "border border-red-400";

        case "RETURN":
          return "border border-blue-400";

        default:
          return "border border-gray-200";
      }
    },
    textColor: function(val) {
      switch (val) {
        case "BOOKED":
          return "text-gray-500";

        case "INFO":
          return "text-yellow-300";

        case "TRANSIT":
          return "text-blue-500";

        case "DELIVERED":
          return "text-green-400";

        case "DEVIATION":
        case "cancelled":
          return "text-red-400";

        case "RETURN":
          return "text-blue-400";

        default:
          return "text-gray-500";
      }
    },
    getPrice: function(item) {
      const val = this.$n(
        this.$store.state.account.business
          ? item.order_price.excl_tax
          : item.order_price.incl_tax,
        "decimal",
        "da-DK"
      );

      if (item.currency)
        return `${val} ${item.currency === "DKK" ? "kr." : item.currency}`;

      return `${val} kr.`;
    }
  }
};
</script>

<style scoped>
.tracking-button:hover ~ .tracking-modal {
  opacity: 1;
  z-index: 50;
}
</style>
