<template>
  <header id="bp_header" class="sticky top-0 z-[100] shadow-md">
    <div class="flex flex-wrap justify-between bg-white p-3 lg:p-5">
      <div v-if="isHomerunner">
        <router-link :to="{ name: 'home' }">
          <img alt="Homerunner Logo" src="https://a.storyblok.com/f/120110/x/c8ab0bf836/homerunner_logo.svg" class="hidden h-9 lg:block" />
          <img src="https://a.storyblok.com/f/120110/x/dd311f5a33/icon.svg" alt="Homerunner Logo" class="h-9 lg:hidden" />
        </router-link>
      </div>
      <div v-else>
        <router-link :to="{ name: 'labels' }">
          <img alt="CoolRunner Logo" src="/img/logo.svg" class="hidden h-9 lg:block" />
          <img
            src="/img/logo_simple.svg"
            alt="CoolRunner
          Logo"
            class="h-9 lg:hidden"
          />
        </router-link>
      </div>

      <div class="header-buttons ml-4 flex items-center justify-start lg:mr-2 lg:justify-end">
        <div class="cursor-pointer rounded-md bg-coolrunner-500 px-3 py-2 font-semibold text-white transition-all duration-300 hover:bg-coolrunner-400" :class="{ 'bg-[#004062] text-white hover:bg-[#0a5075]': account.business }" @click="showCreate">
          {{ $t("send_package") }}
        </div>
        <a href="https://help.homerunner.com/" target="_blank" class="header-button text-coolrunner-900" :class="{ 'text-[#004062]': account.business }">
          {{ $t("help_center") }}
        </a>
        <a href="https://status.coolrunner.dk/" target="_blank" class="header-button text-coolrunner-900" :class="{ 'text-[#004062]': account.business }">
          {{ $t("status") }}
        </a>
        <div class="relative ml-5 mr-3">
          <div class="flex cursor-pointer text-coolrunner-900" :class="{ 'text-[#004062]': account.business }" @click="openOrganisation()">
            {{ account.business ? account.company_name : account.name }} <font-awesome-icon v-if="hasMultipleAccountRes" class="ml-2 mt-1.5 h-3 w-3 transition-all duration-300" :class="[organisationOpen ? 'rotate-180 transform' : '']" :icon="['far', 'chevron-down']" fixed-width />
          </div>
          <div
            v-if="organisationOpen"
            v-click-outside="
              () => {
                organisationOpen ? closeOrganisation() : () => null;
              }
            "
            class="absolute right-0 top-6 w-full min-w-[250px] overflow-hidden rounded bg-white shadow-lg transition-all duration-300"
            :class="[organisationOpen ? 'max-h-[300px]' : 'max-h-0']"
          >
            <div class="p-3">
              <div v-for="acc in $store.state.accountRes" :key="acc.account.id" class="mb-1 flex cursor-pointer" @click="goToSubdomain(acc.subdomain)">
                <p class="text-coolrunner-900 transition-all duration-300 hover:text-opacity-50" :class="{ 'text-[#004062]': acc.business }">{{ acc.account.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="ml-3 h-7 w-7 rounded-full bg-coolrunner-800 pt-[7px] text-xs font-bold uppercase text-white">{{ username }}</div>
      </div>
      <div class="flex flex-grow-0 items-center lg:hidden">
        <div
          color="#004062"
          class="hamburger hamburger--elastic"
          :class="{
            'is-active': menuOpen,
          }"
          @click="menuOpen = !menuOpen"
        >
          <div class="hamburger-box">
            <div class="hamburger-inner" />
          </div>
        </div>
      </div>
    </div>
    <el-drawer size="85%" :visible.sync="menuOpen" direction="ltr" append-to-body>
      <core-menu @entry-click="menuOpen = false" />
    </el-drawer>
  </header>
</template>

<script>
import EventBus from "@/plugins/eventbus";

export default {
  data: () => ({
    menuOpen: false,
    organisationOpen: false,
  }),
  computed: {
    account: function () {
      return this.$store.state.account || {};
    },
    isHomerunner: function () {
      return window.location.hostname === "homerunner.com" || this.account.business;
    },
    hasMultipleAccountRes: function () {
      return this.$store.state.accountRes?.length > 1;
    },
    username: function () {
      let firstAccount = this.$store.state.accountRes ? this.$store.state.accountRes[0] : null;
      let name = firstAccount?.user?.name
      
      if (!name) {
        return "";
      }
      if (name.includes(" ")) {
        let splitstring = name.split(" ");
        return `${splitstring[0].substring(0, 1)}${splitstring[1].substring(0, 1)}`;
      } else {
        return name.substring(0, 2);
      }
    },
  },
  methods: {
    toggleMenu: function () {
      this.menuOpen = !this.menuOpen;
    },
    openOrganisation: function () {
      if (this.hasMultipleAccountRes) {
        this.organisationOpen = true;
      }
    },
    closeOrganisation: function () {
      if (this.organisationOpen) {
        this.organisationOpen = false;
      }
    },
    goToSubdomain: function (subdomain) {
      window.location.replace(`https://${subdomain}.homerunner.com`);
    },
    showCreate: function () {
      EventBus.$emit("SHOW_CREATE_SHIPMENT");
    },
  },
};
</script>
